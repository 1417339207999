.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }


    
    #cards {
      display: flex;
      list-style: none;
      margin-top: 50px;
      padding-top: 5%;
      padding-bottom: 5%;
      justify-content: center;
      flex-wrap: wrap;
      column-gap: 6rem;
      
    }
    
    #cards > li > a {
      position: relative;
      display: block;
      
    }
    
    #cards > li {
      margin-bottom: 50px;
      padding: 0px 10px;
      
    }
    
    #cards a img {
      z-index: 1;
      display: block;
      border-radius: 10%;
    }
    
    #cards a > label {
      font-family: "Proxima Nova";
      font-weight: 700;
      font-size: 34px;
      line-height: 90%;
      color: white;
      text-align: center;
      text-transform: uppercase;
    
      position: absolute;
      bottom: 25px;
      left: 30px;
      right: 30px;
    
      z-index: 2;
    }
    #cards * {
      cursor: pointer;
      
    }
    #cards a::after {
      background: rgba(209, 54, 57, 0);
      border-radius: 10%;
      content: ".";
      font-size: 0;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 3;
    
      transition: background 0.5s;
    }

    #cards a > .hover {
      display: block;
      position: absolute;
      top: 115px;
      left: 0;
      right: 0;
      text-align: center;
      opacity: 0;
      z-index: 4;
      transition: opacity 0.5s;
      
    }
    
    #cards a:hover > .hover {
      opacity: 1;
    }
    
    #cards a > .hover img {
      margin: 0 auto;
    }
    
    #cards a > .hover label {
      display: block;
      margin-top: 20px;
      font-family: "Proxima Nova";
      font-weight: 700;
      font-size: 22px;
      line-height: 118%;
      color: white;
      text-align: center;
      text-transform: uppercase;
      text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
    }
    
    #cards a {
      transition: transform 0.5s;
      
    }
    
    #cards a:hover {
      transform: scale(1.05, 1.05);
      
    }

    #cards .lol a:hover::after {
      background: rgba(53, 53, 81, 0.82);
    }
    #cards .cs a:hover::after {
      background: rgba(229, 167, 87, 0.87);
    }
    *{
      scroll-behavior: smooth;
    }
    a{
      
      color:#ffffff;
      text-decoration: none;
    }
    #cards .tft a:hover::after {
      background: rgba(53, 53, 81, 0.82);
    }
    #cards .ea a:hover::after {
      background: rgba(142, 142, 186, 0.82);
    }
    #cards .hearth a:hover::after {
      background: rgba(50, 105, 168, 0.82);
    }
    #cards .mk a:hover::after {
      background: rgba(65, 30, 69, 0.82);
    }
    #cards .clash_royale a:hover::after {
      background: rgba(66, 132, 231, 0.82);
    }